import { Field, Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GrayCommonBtn from '../../../components/common/Buttons/GrayCommonBtn';
import { Translate } from 'react-localize-redux';
import OutcomeBar from '../../../components/common/OutcomeBar';
import TranslatedLabel from '../../../components/common/TranslatedLabel/TranslatedLabel';
import { positionSchema } from './validationSchemaClient';
import TimePicker from '../../../components/common/TimePicker/TimePicker';
import { allowedCharacters, convertToMarkup,  normalizePhone, onlyDigitsAndDot } from '../../../utils/ReGexs';

import moment from 'moment';
import { DATE_FORMAT } from '../../../components/common/Constants/Constants';
import PositionList from './PositionList/PositionList';

const initialvalues = {
   bill: 0,
   contactName: '',
   contactPhone: '',
   description: '',
   markUp: '',
   name: '',
   otMarkUp: '',
   pay: '',
   timeIn: '',
   timeOut: '',
   otBillRate: 0,
};

const DepartmentsandPositions = ({
   onCloseModal,
   addDepartment,
   editMode = false,
   listDepartment = [],
   setDeletePositionList,
   newDepartment,
}) => {

   const [message, setMessage] = useState('');
   const [positionList, setPositionList] = useState([]);
   const [departmentname, setDepartmentname] = useState('');
   const [errorDepartmentName, setErrorDepartmentName] = useState(false);
   const [editingIndex, setEditingIndex] = useState(null);
   const [formPosition, setFormPosition] = useState(initialvalues);

   const resetComponentPositions = () => {
      setDeletePositionList([]);
      setDepartmentname('');
      setPositionList([]);
      setEditingIndex(null);
      setErrorDepartmentName(false);
      setMessage('');
      setFormPosition(initialvalues);
   };

   // consultamos el formik ,los valores de los campos client para comparar que no se repitan
   const { values } = useFormikContext();

   const editFromPosList = (index) => {
      setEditingIndex(index);
      const position = positionList[index];
      setFormPosition({
         bill: position.bill,
         contactName: position.contactName,
         contactPhone: position.contactPhone,
         description: position.description,
         markUp: position.markUp,
         name: position.name,
         otMarkUp: position.otMarkUp,
         pay: position.pay,
         timeIn: position.timeIn ? moment(position.timeIn).format(DATE_FORMAT) : '',
         timeOut: position.timeOut ? moment(position.timeOut).format(DATE_FORMAT) : '',
      });
   };

   useEffect(() => {
      if (editMode && listDepartment?.name && !newDepartment) {
         setDepartmentname(listDepartment.name);
         setPositionList(listDepartment.positions);
      } else if (newDepartment) {
         setDepartmentname('');
         setPositionList([]);
         setFormPosition(initialvalues);
      } else {
         setDepartmentname('');
         setPositionList([]);
         setFormPosition(initialvalues);
      }
      return () => {
         setPositionList([]);
      };
   }, [editMode, listDepartment, newDepartment]);

   const calculateBillRates = (mark) => {
      let billRate = 0;
      let otBillRate = 0;
      let editmarkup = 0;

      if (mark.otMarkUp) {
         editmarkup = parseFloat(mark.otMarkUp);
         otBillRate = mark.pay * convertToMarkup(editmarkup);
      }

      if (mark.markUp) {
         editmarkup = parseFloat(mark.markUp);
         billRate = mark.pay * convertToMarkup(editmarkup) * 1.5;
      }

      return { billRate, otBillRate };
   };

   const handleAddDepartment = () => {
      if (departmentname === '') {
         setErrorDepartmentName(true);
         return;
      }
      if (positionList.length === 0) {
         return setMessage('com.tempedge.error.client.clientincompleted');
      }
      const isDuplicate = values.departments?.some((dept) => dept.name?.trim().toLowerCase() === departmentname?.trim().toLowerCase());
      if (isDuplicate) {
         setMessage("com.tempedge.error.deptposalreadyexists")
         setErrorDepartmentName(true);
         return;
      }
      addDepartment({
         name: departmentname,
         orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity.orgId,
         positions: positionList,
      });
   };

   const handleBack = () => {
      // Si es un nuevo departamento y el nombre está vacío, cierra el modal
      if (newDepartment && departmentname === '') {
          onCloseModal();
          return;
      }
  
      // Validar si el nombre del departamento está vacío
      if (departmentname === '') {
          setErrorDepartmentName(true);
          return;
      }
  
      // Verificar si existe un duplicado del nombre del departamento en la lista actual
      const isDuplicate = values.departments?.some((dept) => {
          const isSameName = dept.name?.trim().toLowerCase() === departmentname?.trim().toLowerCase();
  
          // Si es un nuevo departamento (sin departmentId) y el nombre ya existe
          if (!listDepartment?.departmentId) {
              // Permitir la edición si es el mismo departamento con el mismo nombre
              return isSameName && dept !== listDepartment;
          }
  
          // Si es un departamento existente, excluir el departamento actual en edición
          return isSameName && dept.departmentId !== listDepartment.departmentId;
      });

      // Mostrar error si es un duplicado
      if (isDuplicate) {
         setMessage("com.tempedge.error.deptposalreadyexists")
          setErrorDepartmentName(true);
          return;
      }
  
      // Si no hay duplicados, proceder a agregar o actualizar el departamento
      addDepartment({
          departmentId: listDepartment?.departmentId,
          name: departmentname,
          orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity.orgId,
          positions: positionList,
      });
  
      // Cerrar el modal después de agregar o actualizar el departamento
      onCloseModal();
  };

  const handleCancel = () => {
   if (editMode) {
       setDepartmentname(listDepartment?.name || '');
       setPositionList(listDepartment?.positions || []);
       setFormPosition(initialvalues);
       setErrorDepartmentName(false);
       
   } else {
      resetComponentPositions();
      setErrorDepartmentName(false);
   }
   setEditingIndex(null);
   setFormPosition(initialvalues);
   setMessage('');
};

   const removeFromPosList = (index, positionId) => {
      setPositionList((prevList) => prevList.filter((_, i) => i !== index));
      if (editMode) {
         setDeletePositionList((prevList) => [...prevList, positionId]);
      }
   };

   const updatePosition = (index, updatedPosition) => {
      setPositionList((prevList) => prevList.map((item, i) => (i === index ? updatedPosition : item)));
   };


   return (
      <Formik
         initialValues={formPosition}
         enableReinitialize={true}
         onSubmit={async (values, { resetForm, setFieldError }) => {
            const { billRate, otBillRate } = calculateBillRates(values);
            const parsedTimeOut = moment(values.timeOut, 'LT').format('HH:mm:ss');
            const parsedTimeIn = moment(values.timeIn, 'LT').format('HH:mm:ss');
            const newPosition = {
               ...values,
               bill: billRate.toFixed(2),
               otBillRate: otBillRate?.toFixed(2),
               timeIn: moment(parsedTimeIn, 'HH:mm:ss').isValid() ? parsedTimeIn : '',
               timeOut: moment(parsedTimeOut, 'HH:mm:ss').isValid() ? parsedTimeOut : '',
            };
            const isDuplicate = positionList?.some(
               (position, index) =>
                  position.name?.trim().toLowerCase() === newPosition.name?.trim().toLowerCase() && index !== editingIndex,
            );

            if (isDuplicate) {
               setFieldError('name', 'The position already exists.');
            } else {
               if (editingIndex !== null) {
                  updatePosition(editingIndex, newPosition);
                  setEditingIndex(null);
               } else {
                  setPositionList((prevList) => [...prevList, newPosition]);
               }
               setFormPosition(initialvalues);
               resetForm(); // Reset the form values
               setMessage(''); // Clear the message
            }
         }}
         validationSchema={positionSchema}
      >
         {({ errors, touched, setFieldValue, setFieldError, values, submitForm }) => {
            const btns = () => (
               <>
                  <Col sm={12} md={4} lg={4}>
                     <GrayCommonBtn tag={'com.tempedge.msg.label.back'} onClick={handleBack} />
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                     <button
                        style={{
                           backgroundColor: '#8cb544',
                           backgroundImage: 'none',
                           color: 'white',
                        }}
                        type="submit"
                        className="btn btn-default btn-block register-save-btn green-btn"
                        onClick={submitForm}
                     >
                        <Translate id="com.tempedge.msg.label.addPos"></Translate>
                     </button>
                  </Col>
                  <Col sm={12} md={4} lg={4}>
                     <button
                        className="btn btn-default btn-block register-save-btn"
                        type={'button'}
                        onClick={newDepartment ? handleAddDepartment : handleCancel }
                     >
                        <Translate
                           id={newDepartment ? 'com.tempedge.msg.label.addDept': 'com.tempedge.msg.label.cancel'}
                        ></Translate>
                     </button>
                  </Col>
               </>
            );
            return (
               <Container className="sign-up-wrapper">
                  <Row>
                     <h2 className="mx-auto text-center">
                        <Translate id="com.tempedge.msg.label.addDept" />
                     </h2>
                  </Row>
                  <Row className="row-agency-name">
                     <Col sm={12} md={12} lg={12}>
                        {message && <OutcomeBar personMessage={message} />}
                     </Col>

                     <Col sm={12} md={4} lg={4}>
                        <TranslatedLabel id="com.tempedge.msg.label.deptName" />
                        <input
                           id={`departmentname`}
                           name={`departmentname`}
                           type="text"
                           maxLength={90}
                           value={departmentname}
                           className={`tempEdge-input-box form-control ${errorDepartmentName ? 'has-error' : ''}`}
                           onChange={(e) => {
                              setDepartmentname(e.target.value.replace(allowedCharacters, '')?.toUpperCase());
                              setErrorDepartmentName(false);
                           }}
                        />
                     </Col>
                     <div className="form-horizontal center-block panel-body mt-3">
                        <Row className="new-client-form ">
                           <Col sm={12} md={8} lg={8} className="client-col">
                              <div className="">
                                 <div className="new-client-header">
                                    <h2>
                                       <Translate id="com.tempedge.msg.label.deptinfo" />
                                    </h2>
                                 </div>
                                 <div className="new-clients-contents">
                                    <div className="client-contents">
                                       <Row>
                                          <Col sm={12} md={8} lg={8} className="form-group">
                                             <Row>
                                                <Col sm={12} md={12} lg={12} className="form-group">
                                                   <TranslatedLabel id="com.tempedge.msg.label.position" />
                                                   <Field
                                                      id={`name`}
                                                      name={`name`}
                                                      type="text"
                                                      maxLength={50}
                                                      className={`tempEdge-input-box form-control ${errors.name && touched.name ? 'has-error' : ''}`}
                                                      onChange={(e) =>
                                                         setFieldValue(
                                                            'name',
                                                            e.target.value.replace(/[^a-zA-ZÀ-ú 0-9 .,-/]+/g, '')?.toUpperCase(),
                                                         )
                                                      }
                                                   />
                                                </Col>
                                                <Col sm={12} md={6} lg={6} className="form-group ">
                                                   <TranslatedLabel id="com.tempedge.msg.label.markup" />
                                                   <Field
                                                      id="markUp"
                                                      name="markUp"
                                                      type="text"
                                                      maxLength="6"
                                                      className={`tempEdge-input-box form-control ${errors.markUp && touched.markUp ? 'has-error' : ''}`}
                                                      onChange={(e) => {
                                                         const value = e.target.value.replace('%', ''); // Remove '%' if it exists
                                                         if (!isNaN(value) || value === '') {
                                                            setFieldValue('markUp', value);
                                                         }
                                                      }}
                                                      value={`${values.markUp}%`} // Display the value with '%' if not empty
                                                   />
                                                </Col>
                                                <Col sm={12} md={6} lg={6} className="form-group ">
                                                   <TranslatedLabel id="com.tempedge.msg.label.OtMarkup" />
                                                   <Field
                                                      id="otMarkUp"
                                                      name="otMarkUp"
                                                      type="text"
                                                      className={`tempEdge-input-box form-control ${errors.otMarkUp && touched.otMarkUp ? 'has-error' : ''}`}
                                                      onChange={(e) => {
                                                         const value = e.target.value.replace('%', ''); // Remove '%' if it exists
                                                         if (!isNaN(value) || value === '') {
                                                            setFieldValue('otMarkUp', value);
                                                         }
                                                      }}
                                                      value={`${values.otMarkUp}%`} // Display the value with '%' if not empty
                                                      maxLength="6"
                                                   />
                                                </Col>
                                             </Row>
                                          </Col>
                                          <Col sm={12} md={4} lg={4}>
                                             <Row>
                                                <Col sm={12} md={12} lg={12} className="form-group w-full">
                                                   <TranslatedLabel id="com.tempedge.msg.label.description" />
                                                   <Field
                                                      id={`description`}
                                                      name={`description`}
                                                      as="textarea"
                                                      maxLength="120"
                                                      className={`text-area-form-department tempEdge-input-box form-control ${
                                                         errors.description && touched.description ? 'has-error' : ''
                                                      }`}
                                                      onChange={(e) =>
                                                         setFieldValue(
                                                            'description',
                                                            (e.target.value = e.target.value.replace(
                                                               /[^a-zA-ZÀ-ú 0-9.,\-/_$!@#]+/g,
                                                               '',
                                                            ))?.toUpperCase(),
                                                         )
                                                      }
                                                      value={values.description}
                                                   />
                                                </Col>
                                             </Row>
                                          </Col>
                                       </Row>
                                       <Row>
                                          <Col sm={12} md={4} lg={4} className="form-group">
                                             <TranslatedLabel id="com.tempedge.msg.label.payRate" />
                                             <Field
                                                id={`pay`}
                                                name={`pay`}
                                                type="text"
                                                maxLength="6"
                                                className={`tempEdge-input-box form-control ${errors.pay && touched.pay ? 'has-error' : ''}`}
                                                onChange={(e) => setFieldValue('pay', (e.target.value = onlyDigitsAndDot(e.target.value)))}
                                             />
                                          </Col>
                                          <Col sm={12} md={4} lg={4} className="form-group">
                                             <TranslatedLabel id="com.tempedge.msg.label.billRate" />
                                             <p className="area-client">
                                                {'$ ' + (values.pay * convertToMarkup(values?.markUp)).toFixed(2)}
                                             </p>
                                          </Col>
                                          <Col sm={12} md={4} lg={4} className="form-group">
                                             <TranslatedLabel id="com.tempedge.msg.label.otBillRate" />
                                             <p className="area-client">
                                                {'$ ' + (values.pay * convertToMarkup(values?.otMarkUp) * 1.5).toFixed(2)}
                                             </p>
                                          </Col>
                                          <Col sm={12} md={6} lg={6} className="form-group">
                                             <TranslatedLabel id="com.tempedge.msg.label.regularSchedule" />
                                             <Field
                                                id={`timeIn`}
                                                name={`timeIn`}
                                                time={values.timeIn}
                                                setTime={(e) => setFieldValue('timeIn', e)}
                                                type="text"
                                                component={TimePicker}
                                             />
                                          </Col>
                                          <Col sm={12} md={6} lg={6} className="form-group mt-3">
                                             <label className="label-p">&nbsp;</label>
                                             <Field
                                                time={values.timeOut}
                                                setTime={(e) => setFieldValue('timeOut', e)}
                                                name="timeOut"
                                                type="text"
                                                component={TimePicker}
                                             />
                                          </Col>
                                          <Col sm={12} md={6} lg={6} className="form-group">
                                             <TranslatedLabel id="com.tempedge.msg.label.employeeContact" />
                                             <Field
                                                id={`contactName`}
                                                name={`contactName`}
                                                type="text"
                                                className={`tempEdge-input-box form-control ${
                                                   errors.contactName && touched.contactName ? 'has-error' : ''
                                                }`}
                                                onChange={(e) =>
                                                   setFieldValue('contactName', e.target.value.replace(/[^a-zA-ZÀ-ú .,-]+/g, ''))
                                                }
                                             />
                                          </Col>
                                          <Col sm={12} md={6} lg={6} className="form-group">
                                             <TranslatedLabel id="com.tempedge.msg.label.contactPhone" />
                                             <Field
                                                id={`contactPhone`}
                                                name={`contactPhone`}
                                                type="text"
                                                className={`tempEdge-input-box form-control ${
                                                   errors.contactPhone && touched.contactPhone ? 'has-error' : ''
                                                }`}
                                                onChange={(e) =>
                                                   setFieldValue('contactPhone', (e.target.value = normalizePhone(e.target.value)))
                                                }
                                             />
                                          </Col>
                                       </Row>
                                    </div>
                                 </div>
                              </div>
                           </Col>
                           <Col sm={12} md={4} lg={4} className="dept-col">
                              <div className="department-list">
                                 <div className="department-list-header">
                                    <h2>
                                       <Translate id="com.tempedge.msg.label.positionlist" />
                                    </h2>
                                 </div>
                                 <div className="position-list-contents">
                                    <div>
                                       <div style={{ height: 10 }}></div>
                                       {positionList?.length > 0 && (
                                          <PositionList
                                             positionList={positionList}
                                             editFromPosList={editFromPosList}
                                             removeFromPosList={removeFromPosList}
                                          />
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  </Row>
                  <Row>{btns()}</Row>
               </Container>
            );
         }}
      </Formik>
   );
};

export default DepartmentsandPositions;
