import { limitString } from '../../../../utils/ReGexs';
import { Translate } from 'react-localize-redux';
import deleteIcon from '../../../../assets/icons/delete.png'; // Tell Webpack this JS file uses this image
import downIcon from '../../../../assets/icons/down.png';
import editIcon from '../../../../assets/icons/edit.png';

const Department = ({ dept, index, onEdit, onDelete, setShowModal }) => {
   const name = limitString({ value: dept?.name, limit: 15, replace: '...' });

   return (
      <div key={`departments-${index}`} id={`departments-${index}`} className="mt-3">
         <div className="btn-dept">
            <a
               className="up-down-arrow pull-left"
               data-toggle="collapse"
               href={`#departments${index}`}
               role="button"
               aria-expanded="false"
               aria-controls={`departments${index}`}
            >
               <img
                  src={downIcon}
                  style={{
                     width: 14,
                     height: 11,
                     display: 'inline',
                     marginLeft: 19,
                  }}
                  alt="downIcon"
               />
            </a>
            <span>{limitString({ value: name, limit: 15, replace: '...' })}</span>
            <span className="pull-right">
               <img src={editIcon} className="client-dpt-btn-edit-delete mr-4" onClick={() => onEdit(index)} alt="editIcon" />
               <img
                  src={deleteIcon}
                  className="client-dpt-btn-edit-delete mr-2"
                  onClick={() => onDelete(index, dept.departmentId ?? null)}
                  alt="deleteIcon"
               />
            </span>
         </div>

         <div className="collapse multi-collapse show" id={`departments${index}`}>
            <div className="card card-body card-department-list">
               <table className="table table-borderless">
                  <thead>
                     <tr>
                        <th>
                           <Translate id="com.tempedge.msg.label.position" />
                        </th>
                        <th>
                           <Translate id="com.tempedge.msg.label.markup" />
                        </th>
                        <th>
                           <Translate id="com.tempedge.msg.label.OtMarkup" />
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {dept?.positions?.map((position, itemIndex) => (
                        <tr key={itemIndex}>
                           <td>{limitString({ value: position.name, limit: 9, replace: '...' })}</td>
                           <td className="text-center">{position.markUp + '%'}</td>
                           <td className="text-center">{position.otMarkUp + '%'}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default Department;
