import { Col, Row } from 'react-bootstrap';
import TranslatedLabel from '../../../../components/common/TranslatedLabel/TranslatedLabel';
import { Field, useFormikContext } from 'formik';
import { Translate } from 'react-localize-redux';
import { normalizePhone } from '../../../../utils/ReGexs';

const ClientThirdPage = ({ editClient = false }) => {
   const { errors, touched, setFieldValue } = useFormikContext();

   const handleChange = (name, event) => {
      const { value } = event.target;
      const sanitizedValue = value.replace(/[^a-zA-ZÀ-ú .,-]+/g, '')?.toUpperCase();
      setFieldValue(name, sanitizedValue);
   };
   return (
      <>
         <div className="new-client-header">
            <h2>
               {!editClient ? (
                  <Translate id="com.tempedge.client.title.createclient"></Translate>
               ) : (
                  <Translate id="com.tempedge.client.title.updateclient"></Translate>
               )}
            </h2>
         </div>
         <div className="new-clients-contents">
            <div className="client-contents">
               <Row className="new-client-form">
                  <Col sm={12} md={6} lg={6} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.lastname" />
                     <Field
                        id="clientlastName"
                        name="clientlastName"
                        type="text"
                        className={` tempEdge-input-box form-control ${errors.clientlastName && touched.clientlastName ? 'has-error' : ''}`}
                        onChange={(e) => handleChange('clientlastName', e)}
                     />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.firstname" />
                     <Field
                        id="clientfirstName"
                        name="clientfirstName"
                        type="text"
                        className={` tempEdge-input-box form-control ${errors.clientfirstName && touched.clientfirstName ? 'has-error' : ''}`}
                        onChange={(e) => handleChange('clientfirstName', e)}
                     />
                  </Col>
               </Row>

               <Row className="form-group bottom-row">
                  <Col sm={12} md={6} lg={6} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.clientcontactphone" />
                     <Field
                        id="clientcontactphone"
                        name="clientcontactphone"
                        type="text"
                        className={` tempEdge-input-box form-control ${errors.clientcontactphone && touched.clientcontactphone ? 'has-error' : ''
                           }`}
                        onChange={(e) => setFieldValue('clientcontactphone', (e.target.value = normalizePhone(e.target.value)))}
                     />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="form-group mt-2">
                     <TranslatedLabel id="com.tempedge.msg.label.clientcontactcellphone" />
                     <Field
                        id="clientcontactcellphone"
                        name="clientcontactcellphone"
                        type="text"
                        className={` tempEdge-input-box form-control ${errors.clientcontactcellphone && touched.clientcontactcellphone ? 'has-error' : ''
                           }`}
                        onChange={(e) => setFieldValue('clientcontactcellphone', (e.target.value = normalizePhone(e.target.value)))}
                     />
                  </Col>
               </Row>
            </div>
         </div>
      </>
   );
};

export default ClientThirdPage;
