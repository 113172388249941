import { useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import GrayCommonBtn from '../../../components/common/Buttons/GrayCommonBtn';
import BlueCommonBtn from '../../../components/common/Buttons/BlueCommonBtn';

const StepNavigation = ({ page, setPage, onCancel }) => {
   const { validateForm, setTouched, touched, submitForm } = useFormikContext();

   const handleNextPage = async () => {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
         setPage((prevPage) => prevPage + 1);
      } else {
         setTouched({
            ...touched,
            ...Object.keys(errors).reduce((acc, key) => {
               acc[key] = true;
               return acc;
            }, {}),
         });
      }
   };

   const handlePreviousPage = () => {
      setPage((prevPage) => prevPage - 1);
   };

   return (
      <Row className="mt-1">
         <Col sm={12} md={page === 1 ? 5 : 4} className={page === 1 ? 'offset-lg-1 mt-2' : 'mt-2'}>
            <GrayCommonBtn tag={'com.tempedge.msg.label.cancel'} onClick={onCancel} />
         </Col>
         {page > 1 && (
            <Col sm={12} md={4} className="mt-2">
               <GrayCommonBtn tag={page > 1 && 'com.tempedge.msg.label.back'} onClick={handlePreviousPage} />
            </Col>
         )}
         <Col sm={12} md={page === 1 ? 5 : 4} className="mt-2">
            <BlueCommonBtn
               tag={page === 4 ? 'com.tempedge.msg.label.save' : 'com.tempedge.msg.label.next'}
               type={'button'}
               onClick={page === 4 ? submitForm : handleNextPage}
            />
         </Col>
      </Row>
   );
};

export default StepNavigation;
