import Department from './Department';

const DepartmentList = ({ departments, onEdit, onDelete, setShowModal, departmentEditList }) => {
   return departments.map((dept, index) => (
      <>
         <Department
            key={index}
            dept={dept}
            index={index}
            onEdit={onEdit}
            onDelete={onDelete}
            setShowModal={setShowModal}
            departmentEditList={departmentEditList}
         />
      </>
   ));
};

export default DepartmentList;
