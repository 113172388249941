import { Col, Row } from 'react-bootstrap';
import { Field, FieldArray, useFormikContext } from 'formik';
import TranslatedLabel from '../../../../components/common/TranslatedLabel/TranslatedLabel';
import DropdownList from '../../../../components/common/Dropdown/DropdownList';
import { fileTypeClient } from '../../../../components/common/Constants/Constants';
import { limitString } from '../../../../utils/ReGexs';
import UploadFile from '../../../../components/common/UploadFile/UploadFile';
import deleteIcon from '../../../../assets/icons/delete.png';
import addIcon from '../../../../assets/icons/plus.png';
import { typeFile } from '../../../../utils/helpers/file';
import { Translate } from 'react-localize-redux';

const ClientFourthPage = ({ editClient = false, setDeleteFileList }) => {
   const { errors } = useFormikContext();

   const link = (fileNames, files) => {
      const type = typeFile(fileNames);
      return `data:${type};base64,${files}`;
   };

   return (
      <>
         <div className="new-client-header">
            <h2>
               {!editClient ? (
                  <Translate id="com.tempedge.client.title.createclient"></Translate>
               ) : (
                  <Translate id="com.tempedge.client.title.updateclient"></Translate>
               )}
            </h2>
         </div>
         <div className="new-clients-contents">
            <div className="client-contents">
               <Row>
                  <FieldArray
                     className="form-group w-100 row"
                     name="fileUpdate"
                     render={(fileUpdate) => (
                        <>
                           {fileUpdate.form.values.fileUpdate.length > 0 ? (
                              fileUpdate.form.values.fileUpdate.map((client, index) => (
                                 <>
                                    <Col key={index} sm={12} md={4} lg={4} className="form-group">
                                       <TranslatedLabel id="com.tempedge.msg.label.filetype" />
                                       <Field
                                          type="text"
                                          name={`fileUpdate.${index}.type`}
                                          data={fileTypeClient}
                                          textField="type"
                                          valueField="type"
                                          value={{ type: client.type }}
                                          component={DropdownList}
                                          onChange={(e) => {
                                             const newType = e.type;
                                             const newFileUpdate = [...fileUpdate.form.values.fileUpdate];
                                             newFileUpdate[index] = {
                                                ...newFileUpdate[index],
                                                type: newType,
                                             };
                                             fileUpdate.form.setFieldValue('fileUpdate', newFileUpdate);
                                          }}
                                          className={`tempEdge-input-box form-control`}
                                          isInvalid={!client.type}
                                       />
                                    </Col>
                                    <Col key={index} sm={12} md={4} lg={4} className="form-group">
                                       <TranslatedLabel id="com.tempedge.msg.label.filename" />
                                       {typeof client.file === 'string' ? (
                                          <span className="  tempEdge-input-box form-control">
                                             <a href={link(client.fileName, client.file)} download={client.fileName}>
                                                {limitString({
                                                   value: client.fileName || '',
                                                   limit: 25,
                                                   replace: '...',
                                                })}
                                             </a>
                                          </span>
                                       ) : (
                                          <Field
                                             className={`form-control tempEdge-input-box ${errors.UploadFile && 'has-error'}`}
                                             name={`fileUpdate.${index}.fileName`}
                                             id={`fileUpdate.${index}.fileName`}
                                          />
                                       )}
                                    </Col>
                                    <Col  key={index} sm={10} md={3} lg={3} className=" form-group w-100 mt-1">
                                       <UploadFile
                                          accept="application/msword,application/pdf"
                                          onFileSelectSuccess={(files) => {
                                             const newFileUpdate = [...fileUpdate.form.values.fileUpdate];
                                             newFileUpdate[index] = {
                                                ...newFileUpdate[index],
                                                fileName: files.name,
                                                file: files,
                                             };
                                             fileUpdate.form.setFieldValue('fileUpdate', newFileUpdate);
                                          }}
                                          onFileSelectError={({ error }) => console.error(error)}
                                          fileNames={client.fileNames ?? ''}
                                          fileUpdate={client.file}
                                       />
                                    </Col>
                                    <Col key={index} sm={2} md={1} lg={1} className="mt-auto form-group">
                                       <span
                                          className="center-block pull-right mb-5 ml-5 pull-right w-100 mt-1"
                                          title="Remove file"
                                          onClick={() => {
                                             const newFileUpdate = [...fileUpdate.form.values.fileUpdate];
                                             if (typeof client.file === 'string' && editClient) {
                                                setDeleteFileList((prev) => [...prev, client]);
                                             }
                                             newFileUpdate.splice(index, 1);
                                             fileUpdate.form.setFieldValue('fileUpdate', newFileUpdate);
                                          }}
                                          style={{ paddingRight: '1.3rem' }}
                                       >
                                          <img className="delete-icon-phones" src={deleteIcon} alt="deleteIcon" />
                                       </span>
                                    </Col>
                                 </>
                              ))
                           ) : (
                              <Col sm={12} md={12} lg={12}>
                                 <div className="mt-5 ml-5">
                                    <h2>
                                       <TranslatedLabel id="com.tempedge.msg.label.emptyfile" />
                                    </h2>
                                 </div>
                              </Col>
                           )}

                           <Col sm={12} md={12} lg={12} className="mt-5 border-top">
                              <span
                                 style={{ paddingRight: '2.2rem' }}
                                 className="center-block pull-right add-fieldArray-btn"
                                 onClick={() => {
                                    fileUpdate.push({
                                       type: '',
                                       fileName: '',
                                       file: '',
                                    });
                                 }}
                              >
                                 <img style={{ width: '42px', paddingTop: '12px' }} src={addIcon} alt="addIcon" />
                              </span>
                           </Col>
                        </>
                     )}
                  />
               </Row>
            </div>
         </div>
      </>
   );
};

export default ClientFourthPage;
