// Definimos un array con los días de la semana
const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Usamos la función map para generar un nuevo array. Esta función recorre cada elemento del array original (en este caso, cada día de la semana)
const payrollCycle = daysOfWeek.map((day, index) => {
   // Para cada día, calculamos el día siguiente. Usamos el operador módulo (%) para volver al inicio del array cuando llegamos al final.
   const nextDay = daysOfWeek[(index + 1) % daysOfWeek.length];

   // Para cada día, retornamos un objeto con la estructura requerida. Usamos plantillas de cadena de texto (template strings) para generar la cadena con el ciclo de nómina.
   return {
      payrollCycle: `${day} - ${nextDay}`,
      payrollId: index,
   };
});

export default payrollCycle;
